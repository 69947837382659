import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: ${(props) => Math.abs(props.page - 4)};
  ${(props) =>
    props.enableAnimation &&
    `
    transition: top 1000ms;
    transition-delay: ${props.isActive ? 0 : props.delay}ms;
    transition-timing-function: ease;
    will-change: top;
    top: ${props.isActive ? 0 : -100}vh;
  `}
`;

const Component = ({ enableAnimation, step, page, children, delay }) => {
  const isActive = page === step;
  return (
    <Section delay={delay} page={page} isActive={isActive} enableAnimation={enableAnimation}>
      {children}
    </Section>
  );
};

Component.propTypes = {
  page: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
  enableAnimation: PropTypes.bool.isRequired,
  delay: PropTypes.number.isRequired,
};

Component.defaultProps = {
  enableAnimation: false,
  delay: 0,
};

export default Component;
