import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MediaQueries } from '../../configuration/theme';
import { rotate, textAppear, translateX, translateY } from '../animations';
import CardImage from '../card-image/card-image';

const translateXToRight = translateX({ from: '100vw', to: '0' });
const translateXLeftToRight = translateX({ from: '-100vw', to: '0' });
const translateYDownToUp = translateY({ from: '100vh', to: '0vh' });
const rotation = (degrees) => rotate({ from: '0deg', to: `${degrees}deg` });

const CLIP_PATH_POLYGON = [
  'polygon(-50% -50%, 150% -50%, 150% 150%, -50% 150%)',
  'polygon(50% -50%, 150% -100%,150% 150%,50% 150%)',
  'polygon(50% -50%, 150% -100%,150% 150%,50% 150%)',
  'polygon(0 -50%, 150% -100%, 150% 150%, 50% 150%, 50% 50%, -50% 50%)',
];

const Wrapper = styled.div`
  padding: 10vw 2vw 0;
  height: 100%;
  @media screen and ${MediaQueries.xs} {
    display: flex;
    flex-direction: column;
    padding: 5vw 5vw 0;
  }
  @media screen and ${MediaQueries.md} {
    flex-direction: row;
  }
  @media screen and ${MediaQueries.lg} {
    padding: 6vw 8vw 0 6vw;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: -2;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #183b72;
    animation-name: ${(props) => (props.isActive ? translateYDownToUp.enter : translateYDownToUp.leave)};
    animation-delay: ${(props) => (props.isActive ? 1400 : 0)}ms;
    animation-duration: 1200ms;
    animation-timing-function: ease;
    animation-fill-mode: both;
    z-index: -1;
    max-height: 100%;
    height: 58vw;
    @media screen and ${MediaQueries.sm} {
      height: 30vw;
    }
    @media screen and ${MediaQueries.md} {
      height: 18vw;
    }
    @media screen and ${MediaQueries.lg} {
      height: 13vw;
    }
  }
`;

const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and ${MediaQueries.md} {
    margin: 0;
  }
`;

const Span = styled.span`
  display: block;
  margin: 0 auto 0;
  font-family: 'Aktiv Grotesk Corp', Arial;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0px;
  color: #000;
  background-repeat: no-repeat;
  animation-name: ${(props) => (props.isActive ? textAppear.enter : textAppear.leave)};
  animation-duration: 1200ms;
  animation-delay: ${(props) => (props.isActive ? (props.index + 1) * 600 : 0)}ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
  width: 100%;
  text-transform: initial;
  font-size: 4vw;
  line-height: 5vw;
  max-width: 80vw;
  @media screen and ${MediaQueries.xs} {
    font-size: 4.5vw;
    line-height: 5.5vw;
    max-width: 78vw;
  }
  @media screen and ${MediaQueries.sm} {
    font-size: 2vw;
    line-height: 2.5vw;
    max-width: 74vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 1.5vw;
    line-height: 2vw;
    text-align: start;
    max-width: 46vw;
    margin: 0;
  }
`;

const Highlight = styled.b`
  font-size: 10vw;
  line-height: 11vw;
  font-family: 'Aktiv Grotesk Corp', Arial;
  color: #183b72;
  text-align: center;
  background-repeat: no-repeat;
  text-transform: uppercase;
  animation-name: ${(props) => (props.isActive ? textAppear.enter : textAppear.leave)};
  animation-duration: 1200ms;
  animation-delay: ${(props) => (props.isActive ? (props.index + 1) * 600 : 0)}ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
  font-weight: 900;
  @media screen and ${MediaQueries.sm} {
    font-size: 4vw;
    line-height: 4vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 4vw;
    line-height: 4.5vw;
    text-align: start;
  }
  @media screen and ${MediaQueries.lg} {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
`;

const LogoWrapper = styled.div`
  display: ${(props) => (props.viewMode === 'desktop' ? 'none' : 'flex')};
  align-items: center;
  position: relative;
  align-self: flex-start;
  animation-name: ${(props) => (props.isActive ? translateXLeftToRight.enter : translateXLeftToRight.leave)};
  animation-fill-mode: both;
  animation-duration: 2000ms;
  animation-delay: ${(props) => (props.isActive ? 2100 : 0)}ms;
  animation-timing-function: ease;
  margin: 0 auto;
  height: 20vw;
  @media screen and ${MediaQueries.xs} {
    height: 50vw;
  }
  @media screen and ${MediaQueries.sm} {
    height: 30vw;
    align-items: center;
    top: 0;
    margin: 0 auto;
  }
  @media screen and ${MediaQueries.md} {
    display: ${(props) => (props.viewMode === 'desktop' ? 'flex' : 'none')};
    height: 18vw;
    margin: 0;
  }
  @media screen and ${MediaQueries.lg} {
    height: 13vw;
    margin: 0;
  }
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  height: 13vw;
  max-height: 136px;
  @media screen and ${MediaQueries.sm} {
    height: 7vw;
  }
  @media screen and ${MediaQueries.md} {
    height: 8vw;
  }
  @media screen and ${MediaQueries.lg} {
    height: 7.1vw;
  }
`;

const ImagesWrapper = styled.div`
  position: relative;
  animation-name: ${(props) => (props.isActive ? translateYDownToUp.enter : translateXToRight.leave)};
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-duration: 1600ms;
  animation-delay: ${(props) => (props.isActive ? 2100 : 0)}ms;
  width: 35vw;
  height: 42vw;
  margin: 0 auto;
  @media screen and ${MediaQueries.xs} {
    width: 50vw;
    height: 60vw;
  }
  @media screen and ${MediaQueries.sm} {
    margin: 5vw 0 7vw;
    width: 23.3vw;
    height: 27vw;
  }
  @media screen and ${MediaQueries.md} {
    width: 20.3vw;
    height: 24vw;
  }
  @media screen and ${MediaQueries.lg} {
    top: -3.3vw;
    width: 21.3vw;
    height: 25vw;
    margin: 0;
  }
`;

const CardImageWrapper = styled.div`
  position: absolute;
  animation-name: ${(props) => (props.isActive ? props.rotationProperty.enter : props.rotationProperty.leave)};
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-duration: 2000ms;
  animation-delay: 2500ms;
  z-index: ${(props) => props.index};
  clip-path: ${(props) => props.clipPathInfo};
  filter: drop-shadow(0 0 8px rgba(50, 50, 0, 0.5));
  width: 35vw;
  height: 42vw;
  @media screen and ${MediaQueries.xs} {
    width: 50vw;
    height: 60vw;
  }
  @media screen and ${MediaQueries.sm} {
    width: 23.3vw;
    height: 27vw;
  }
  @media screen and ${MediaQueries.md} {
    width: 20.3vw;
    height: 24vw;
  }
  @media screen and ${MediaQueries.lg} {
    width: 21.3vw;
    height: 25vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const ImagesContainer = styled.div`
  margin: 6vw auto;
  @media screen and ${MediaQueries.xs} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12vw 0;
    flex-grow: 1;
  }
  @media screen and ${MediaQueries.sm} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0;
  }
`;

const HomeEndSection = ({ step, page, updateStep }) => {
  const isActive = step === page;
  const handleOnTextClicked = (event) => {
    updateStep(page + 1);
    setTimeout(() => {
      event.target.dispatchEvent(new CustomEvent('start-application', { bubbles: true }));
    }, 2000);
  };
  return (
    <Wrapper isActive={isActive}>
      <TextContainer>
        <TitleWrapper>
          <H1>
            <Span isActive={isActive} index={1}>
              That's why the
            </Span>
            <Highlight isActive={isActive} index={2}>
              Clean Cooking Alliance
            </Highlight>
            <Span isActive={isActive} index={3}>
              remains committed to accelerating access to clean cooking and to the health, economic, and environmental
              benefits it can deliver to families around the world.
            </Span>
          </H1>
        </TitleWrapper>
        <LogoWrapper isActive={isActive} viewMode="desktop">
          <Logo src={`${process.env.PUBLIC_URL}/images/logo-cca-text.webp`} />
        </LogoWrapper>
      </TextContainer>
      <ImagesContainer>
        <ImagesWrapper isActive={isActive}>
          <CardImageWrapper
            onClick={handleOnTextClicked}
            clipPathInfo={CLIP_PATH_POLYGON[0]}
            isActive={isActive}
            index="3"
            rotationProperty={rotation(0)}
          >
            <CardImage text="Enter the website" image={`${process.env.PUBLIC_URL}/images/polaroid1.webp`} />
          </CardImageWrapper>
          <CardImageWrapper
            clipPathInfo={CLIP_PATH_POLYGON[1]}
            isActive={isActive}
            index="2"
            rotationProperty={rotation(25)}
          >
            <CardImage scale={0.9} image={`${process.env.PUBLIC_URL}/images/polaroid2.webp`} />
          </CardImageWrapper>
          <CardImageWrapper
            clipPathInfo={CLIP_PATH_POLYGON[2]}
            isActive={isActive}
            index="1"
            rotationProperty={rotation(50)}
          >
            <CardImage scale={0.9} image={`${process.env.PUBLIC_URL}/images/polaroid3.webp`} />
          </CardImageWrapper>
          <CardImageWrapper
            clipPathInfo={CLIP_PATH_POLYGON[3]}
            isActive={isActive}
            index="0"
            rotationProperty={rotation(-25)}
          >
            <CardImage scale={0.9} image={`${process.env.PUBLIC_URL}/images/polaroid3.webp`} />
          </CardImageWrapper>
        </ImagesWrapper>
      </ImagesContainer>
      <LogoWrapper isActive={isActive} viewMode="mobile">
        <Logo src={`${process.env.PUBLIC_URL}/images/logo-cca-text.webp`} />
      </LogoWrapper>
    </Wrapper>
  );
};

HomeEndSection.propTypes = {
  step: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  updateStep: PropTypes.func.isRequired,
};

export default HomeEndSection;
