import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 8vw;
  background: #569d44;
  border-radius: 100%;
`;

const Arrow = styled.i`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
`;

const SwipeAnimation = () => {
  return (
    <Wrapper>
      <Arrow />
    </Wrapper>
  );
};

export default SwipeAnimation;
