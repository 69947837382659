import { keyframes } from 'styled-components';

export const rotate = ({ from, to }) => ({
  enter: keyframes`
        from {
            transform: rotate(${from});
        }
        to {
            transform: rotate(${to});
        }
    `,
  leave: keyframes`
        from {
            transform: rotate(${to});
        }
        to {
            transform: rotate(${from});
        }
    `,
});

export const height = ({ from, to }) => ({
  enter: keyframes`
        from {
            height: ${from};
        }
        to {
            height: ${to};
        }
    `,
  leave: keyframes`
        from {
            height: ${to};
        }
        to {
            height: ${from};
        }
    `,
});

export const translateY = ({ from, to }) => ({
  enter: keyframes`
        from {
            transform: translateY(${from});
        }
        to {
            transform: translateY(${to});
        }
    `,
  leave: keyframes`
        from {
            transform: translateY(${to});
        }
        to {
            transform: translateY(${from});
        }
    `,
});

export const translateX = ({ from, to }) => ({
  enter: keyframes`
        from {
            transform: translateX(${from});
        }
        to {
            transform: translateX(${to});
        }
    `,
  leave: keyframes`
        from {
            transform: translateX(${to});
        }
        to {
            transform: translateX(${from});
        }
    `,
});

export const mainLogo = {
  enter: keyframes`
        0% {
            margin-top: 100%;
            transform: rotate(0deg) scale(0);
        }
        50% {
            margin-top: 100%;
            transform: rotate(180deg) scale(1);
        }
        100% {
            margin-top: 0;
            transform: rotate(180deg) scale(1);
        }
    `,
  leave: keyframes`
        0% {
            margin-top: 0;
            transform: rotate(180deg) scale(1);
        }
        50% {
            margin-top: 100%;
            transform: rotate(180deg) scale(1);
        }
        100% {
            margin-top: 100%;
            transform: rotate(0deg) scale(0);
        }
  `,
};

export const textAppear = {
  enter: keyframes`
        from {
            transform: translateY(-50px);
            opacity: 0;
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%);
        }
        to {
            transform: translateY(0);
            opacity: 1;
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
        }
    `,
  leave: keyframes`
        from {
            transform: translateY(0);
            opacity: 1;
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        }
        to {
            opacity: 0;
            clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
        }
    `,
};

export const mouseScroll = keyframes`
  to {
    top: 8px;
  }
`;
