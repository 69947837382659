import { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const onClickUpdateStep = ({ initial, max, step, direction }, onUpdateStep) => {
  if (direction === 'next') {
    if (max > step) {
      onUpdateStep(step + 1);
    }
  } else {
    if (step > initial) {
      onUpdateStep(step - 1);
    }
  }
};

const useSteps = ({ initial = 1, max }) => {
  const [step, setStep] = useState(initial);
  const [disabled, setDisabled] = useState(false);
  const handlers = useSwipeable(
    {
      onSwiped: ({ dir }) => {
        if (['Up', 'Left'].includes(dir)) {
          onClickUpdateStep({ initial, max, step, direction: 'next' }, setStep);
        } else {
          onClickUpdateStep({ initial, max, step, direction: 'previous' }, setStep);
        }
      },
    },
    { delta: 50 }
  );
  useEffect(() => {
    const onWheel = ({ deltaY }) => {
      if (!disabled) {
        setDisabled(true);
        const timeout = setTimeout(() => {
          setDisabled(false);
          clearTimeout(timeout);
        }, 2500);
        if (deltaY > 0) {
          onClickUpdateStep({ initial, max, step, direction: 'next' }, setStep);
        } else {
          onClickUpdateStep({ initial, max, step, direction: 'previous' }, setStep);
        }
      }
    };
    window.addEventListener('wheel', onWheel);
    return () => window.removeEventListener('wheel', onWheel);
  }, [initial, disabled, max, step]);
  return [
    { step, handlers },
    { setStep, setDisabled },
  ];
};

export default useSteps;
