import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { MediaQueries } from '../../configuration/theme';

export const opacityMinMax = keyframes`
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.3;
  }
  100% {
      opacity: 1;
  }
`;

const Text = styled.span`
  font-family: 'Reanie Beanie';
  text-align: center;
  width: 100%;
  animation-name: ${opacityMinMax};
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 5vw;
  @media screen and ${MediaQueries.xs} {
    font-size: 7vw;
  }
  @media screen and ${MediaQueries.sm} {
    font-size: 2.5vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 2.5vw;
  }
  @media screen and ${MediaQueries.lg} {
    font-size: 2.5vw;
  }
`;

const Wrapper = styled.div`
  position: relative;
  background: #fff;
  background-image: url(${(props) => props.backgroundUrl});
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: color 300ms;
  transform: scale(${(props) => props.transformScale});
  &:hover {
    color: #569d44;
    ${Text} {
      animation-name: none;
      opacity: 1;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 10vw;
  @media screen and ${MediaQueries.sm} {
    height: 4.75vw;
  }
`;

const CardImage = ({ scale, image, text }) => {
  return (
    <Wrapper transformScale={scale} backgroundUrl={image}>
      {text && (
        <TextWrapper>
          <Text>{text}</Text>
        </TextWrapper>
      )}
    </Wrapper>
  );
};

CardImage.propTypes = {
  image: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
};

CardImage.defaultProps = {
  scale: 1.0,
};

export default CardImage;
