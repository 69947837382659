import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { mouseScroll } from '../animations';

const Mouse = styled.div`
  position: relative;
  width: 34px;
  height: 52px;
  border-radius: 15px;
  border: ${(props) => props.strokeSize}px solid #fff;
`;

const Button = styled.div`
  background-color: #fff;
  width: ${(props) => `${props.strokeSize}px`};
  height: 18px;
  border-radius: ${(props) => `${props.strokeSize}px`};
  position: absolute;
  top: ${(props) => `-${props.strokeSize}px`};
  left: 50%;
  transform: translateX(-50%);
  &:after {
    content: '';
    height: 9px;
    width: 6px;
    display: block;
    background: rgba(0, 30, 75, 1);
    border: ${(props) => `${props.strokeSize}px`} solid #fff;
    position: absolute;
    top: 3px;
    left: ${(props) => `-${props.strokeSize}px`};
    border-radius: ${(props) => `${props.strokeSize * 2}px`};
    animation: ${mouseScroll} 800ms infinite alternate;
  }
`;

const MouseScrollAnimation = ({ stroke }) => {
  return (
    <Mouse strokeSize={stroke}>
      <Button strokeSize={stroke} />
    </Mouse>
  );
};

MouseScrollAnimation.propTypes = {
  stroke: PropTypes.number.isRequired,
};

MouseScrollAnimation.defaultProps = {
  stroke: 2,
};

export default MouseScrollAnimation;
