import { createGlobalStyle } from 'styled-components';

export const Sizes = {
  xs: '360px',
  sm: '768px',
  md: '1024px',
  lg: '1366px',
  xl: '1920px',
};

export const MediaQueries = {
  xs: `(min-width: ${Sizes.xs})`,
  sm: `(min-width: ${Sizes.sm})`,
  md: `(min-width: ${Sizes.md})`,
  lg: `(min-width: ${Sizes.lg})`,
  xl: `(min-width: ${Sizes.xl})`,
};

export const DefaultTheme = {};

export const GlobalStyle = createGlobalStyle`
    body {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    #root *, *:after, *:before {
      box-sizing: border-box;
    }
    html, body, #root {
      height: 100%;
    }
`;
