import React, { memo, useRef } from 'react';
import styled from 'styled-components';

import { HomeCookingSection, HomeEndSection, HomeInitialSection, Section } from '../../components';
import { useSteps } from '../../hooks';

const HomePageWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const INITIAL_SECTION_PAGE = 1;
const COOKING_SECTION_PAGE = 2;
const END_SECTION_PAGE = 3;

const HomePage = () => {
  const [{ step, handlers }, { setStep }] = useSteps({
    initial: INITIAL_SECTION_PAGE,
    max: END_SECTION_PAGE,
  });
  const ref = useRef();
  const handleRef = (element) => {
    handlers.ref(element);
    ref.current = element;
  };
  return (
    <HomePageWrapper ref={handleRef}>
      <Section page={INITIAL_SECTION_PAGE} step={step} enableAnimation>
        <HomeInitialSection page={INITIAL_SECTION_PAGE} step={step} updateStep={setStep} />
      </Section>
      <Section page={COOKING_SECTION_PAGE} step={step} enableAnimation delay={1400}>
        <HomeCookingSection page={COOKING_SECTION_PAGE} step={step} />
      </Section>
      <Section page={END_SECTION_PAGE} step={step}>
        <HomeEndSection page={END_SECTION_PAGE} step={step} updateStep={setStep}></HomeEndSection>
      </Section>
    </HomePageWrapper>
  );
};

export default memo(HomePage);
