import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MediaQueries } from '../../configuration/theme';
import { textAppear, translateY } from '../animations';

const translateYDownToUp = translateY({ from: '100vh', to: '0vh' });

const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: 'Aktiv Grotesk Corp', Arial;
`;

const Span = styled.span`
  display: block;
  font-weight: 300;
  text-align: center;
  background-repeat: no-repeat;
  animation-name: ${(props) => (props.isActive ? textAppear.enter : textAppear.leave)};
  animation-duration: 2000ms;
  animation-delay: ${(props) => (props.isActive ? 1000 : 0)}ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
  width: 100%;
  color: #000;
  font-size: 5vw;
  letter-spacing: -0.5px;
  @media screen and ${MediaQueries.xs} {
    font-size: 5vw;
  }
  @media screen and ${MediaQueries.sm} {
    font-size: 4vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 1.5vw;
    letter-spacing: -1px;
  }
`;

const Highlight = styled.b`
  font-weight: 900;
  color: #183b72;
  text-align: center;
  text-transform: uppercase;
  animation-name: ${(props) => (props.isActive ? textAppear.enter : textAppear.leave)};
  animation-duration: 1800ms;
  animation-delay: ${(props) => (props.isActive ? 1000 : 0)}ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
  font-size: 8.5vw;
  line-height: 8.5vw;
  @media screen and ${MediaQueries.xs} {
    font-size: 11vw;
    line-height: 11vw;
  }
  @media screen and ${MediaQueries.sm} {
    font-size: 6vw;
    line-height: 6vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 5vw;
    line-height: 5vw;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: -2;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 58vw;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #73c6e5;
    animation-name: ${(props) => (props.isActive ? translateYDownToUp.enter : translateYDownToUp.leave)};
    animation-delay: ${(props) => (props.isActive ? 600 : 0)}ms;
    animation-duration: 1200ms;
    animation-timing-function: ease;
    animation-fill-mode: both;
    z-index: -1;
    max-height: 100%;
    @media screen and ${MediaQueries.sm} {
      height: 30vw;
    }
    @media screen and ${MediaQueries.md} {
      height: 13vw;
    }
  }
`;

const TitleWrapper = styled.div`
  margin: 22vw auto 12vw;
  @media screen and ${MediaQueries.xs} {
    margin: 16vw auto 14vw;
  }
  @media screen and ${MediaQueries.sm} {
    margin: 3vw auto;
  }
  @media screen and ${MediaQueries.md} {
    height: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 10vw 10vw;
  height: 0;
  flex-grow: 1;
  animation-name: ${(props) => (props.isActive ? translateYDownToUp.enter : translateYDownToUp.leave)};
  animation-duration: 2000ms;
  animation-delay: ${(props) => (props.isActive ? 500 : 0)}ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 20%));
  @media screen and ${MediaQueries.md} {
    margin: 0 6vw;
    flex-grow: 2;
  }
  @media screen and ${MediaQueries.lg} {
  }
`;

const ImageDesktop = styled.img`
  display: none;
  @media screen and ${MediaQueries.md} {
    display: block;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const ImageMobile = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media screen and ${MediaQueries.md} {
    display: none;
  }
`;

const HomeCookingSection = ({ page, step }) => {
  const isActive = page === step;
  return (
    <Wrapper isActive={isActive}>
      <TitleWrapper>
        <H1>
          <Span isActive={isActive}>One thing that hasn't changed</Span>
          <Span isActive={isActive}>is the significance of</Span>
          <Highlight isActive={isActive}>cooking</Highlight>
          <Span isActive={isActive}>in daily life.</Span>
        </H1>
      </TitleWrapper>
      <ImageWrapper isActive={isActive}>
        <ImageDesktop src={process.env.PUBLIC_URL + '/images/stage2.webp'} />
        <ImageMobile src={process.env.PUBLIC_URL + '/images/stage2_mobile.webp'} />
      </ImageWrapper>
    </Wrapper>
  );
};

HomeCookingSection.propTypes = {
  step: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default HomeCookingSection;
