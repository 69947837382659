import React from 'react';
import { hydrate, render } from 'react-dom';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { DefaultTheme, GlobalStyle } from './configuration/theme';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ThemeProvider theme={DefaultTheme}>
        <App />
        <GlobalStyle />
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <ThemeProvider theme={DefaultTheme}>
        <App />
        <GlobalStyle />
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
}
