import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MediaQueries } from '../../configuration/theme';
import { mainLogo, textAppear, translateY } from '../animations';
import MouseScrollAnimation from '../mouse-scroll-animation/mouse-scroll-animation';
import SwipeAnimation from '../swipe-animation/swipe-animation';

const translateYDownToUp = translateY({ from: '100vh', to: '0vh' });

const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: 'Aktiv Grotesk Corp', Arial;
`;

const Span = styled.span`
  display: block;
  font-weight: 300;
  text-align: center;
  background-repeat: no-repeat;
  animation-name: ${(props) => (props.isActive ? textAppear.enter : textAppear.leave)};
  animation-duration: 2000ms;
  animation-delay: 1000ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
  width: 100%;
  color: #fff;
  font-size: 5vw;
  line-height: 6vw;
  letter-spacing: -0.5px;
  @media screen and ${MediaQueries.xs} {
    line-height: 6vw;
    font-size: 5vw;
  }
  @media screen and ${MediaQueries.sm} {
    line-height: 3vw;
    font-size: 2.5vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 1.5vw;
    line-height: 2vw;
    letter-spacing: -1px;
  }
`;

const Highlight = styled.b`
  font-weight: 900;
  color: #73c6e5;
  text-align: center;
  text-transform: uppercase;
  animation-name: ${(props) => (props.isActive ? textAppear.enter : textAppear.leave)};
  animation-duration: 2000ms;
  animation-delay: 1000ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
  font-size: 8.5vw;
  line-height: 9vw;
  @media screen and ${MediaQueries.xs} {
    font-size: 9vw;
    line-height: 9.5vw;
  }
  @media screen and ${MediaQueries.sm} {
    font-size: 4.5vw;
    line-height: 5.5vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 2.5vw;
    line-height: 3vw;
    margin: 0.5vw 0;
  }
`;

const Bold = styled.b`
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #183b72;
  padding: 0 3vw;
  @media screen and ${MediaQueries.xs} {
    padding: 0 8.5vw;
  }
  @media screen and ${MediaQueries.sm} {
    padding: 0;
  }
`;

const ScrollIndicator = styled.div`
  margin: 10vw 0 0;
  @media screen and ${MediaQueries.sm} {
    margin: 8vw 0 0;
  }
  @media screen and ${MediaQueries.md} {
    margin: 4vw 0 0;
  }
`;

const MouseScrollAnimationWrapper = styled.div`
  display: none;
  will-change: transform;
  animation-name: ${(props) => (props.isActive ? translateYDownToUp.enter : translateYDownToUp.leave)};
  animation-duration: 2000ms;
  animation-fill-mode: both;
  animation-delay: 2000ms;
  @media screen and ${MediaQueries.sm} {
    display: block;
  }
`;

const SwipeAnimationWrapper = styled.div`
  display: block;
  will-change: transform;
  animation-name: ${(props) => (props.isActive ? translateYDownToUp.enter : translateYDownToUp.leave)};
  animation-duration: 2000ms;
  animation-fill-mode: both;
  animation-delay: 2000ms;
  @media screen and ${MediaQueries.sm} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 44vw;
  height: 44vw;
  margin: 0 0 14vw;
  @media screen and ${MediaQueries.sm} {
    width: 28vw;
    height: 28vw;
    margin: 0 0 9vw;
  }
  @media screen and ${MediaQueries.md} {
    width: 13vw;
    height: 13vw;
    margin: 0 0 4vw;
  }
  @media screen and ${MediaQueries.lg} {
    max-width: 200px;
    max-height: 200px;
  }
  @media screen and ${MediaQueries.xl} {
    max-width: 250px;
    max-height: 250px;
  }
`;

const Logo = styled.img`
  will-change: transform;
  animation-name: ${(props) => (props.isActive ? mainLogo.enter : mainLogo.leave)};
  animation-duration: 2000ms;
  animation-delay: ${(props) => (props.isActive ? 0 : 2000)}ms;
  animation-fill-mode: both;
  width: 100%;
  height: 100%;
`;

const ButtonSkipIntro = styled.button`
  position: absolute;
  bottom: 10px;
  outline: 0;
  border: none;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Aktiv Grotesk Corp', Arial;
  font-weight: 300;
  padding: 1rem 1.5rem;
  transition: all 300ms;
  cursor: pointer;
  font-size: 4vw;
  letter-spacing: -0.5px;
  @media screen and ${MediaQueries.sm} {
    font-size: 2vw;
  }
  @media screen and ${MediaQueries.md} {
    font-size: 1vw;
    letter-spacing: -1px;
  }
  &:hover {
    color: #569d44;
  }
`;

const HomeInitialSection = ({ page, step, updateStep }) => {
  const isActive = page === step;
  const handleOnClick = (event) => {
    updateStep(9);
    setTimeout(() => {
      event.target.dispatchEvent(new CustomEvent('start-application', { bubbles: true }));
    }, 2000);
  };
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo isActive={isActive} src={`${process.env.PUBLIC_URL}/images/logo-cca.webp`} />
      </LogoWrapper>
      <H1>
        <Span isActive={isActive}>A lot has changed</Span>
        <Span isActive={isActive}>
          in the <Bold>10 years</Bold> since the
        </Span>
        <Highlight isActive={isActive}>Clean Cooking Alliance</Highlight>
        <Span isActive={isActive}>was launched.</Span>
      </H1>
      <ScrollIndicator>
        <MouseScrollAnimationWrapper isActive={isActive}>
          <MouseScrollAnimation />
        </MouseScrollAnimationWrapper>
        <SwipeAnimationWrapper isActive={isActive}>
          <SwipeAnimation />
        </SwipeAnimationWrapper>
      </ScrollIndicator>
      <ButtonSkipIntro onClick={handleOnClick}>Skip Intro</ButtonSkipIntro>
    </Wrapper>
  );
};

HomeInitialSection.propTypes = {
  step: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  updateStep: PropTypes.func.isRequired,
};

export default HomeInitialSection;
